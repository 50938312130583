import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout className="mt-20">
    <SEO title="404: Not found" />
    <div className="text-center max-w-sm mx-auto">
      <h1 className="text-6xl font-extrabold">404</h1>
      <p className="mt-5">
        The page you are looking for is not found. May be this page is not
        created, deleted, moved or is hiding out in quarentine.
      </p>
      <p className="mt-5">Lets start from homepage.</p>
      <p className="mt-10">
        <Link
          to="/"
          className="border border-blue-500 rounded px-4 py-2 hover:bg-blue-500 hover:text-white"
        >
          Go Home
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
